.mobile_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    margin-bottom: 0px;

  }
}

.App-link_mobile {
  color: #000000;
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 1000;
  line-height: 30.12px;
  padding: 20px 0;
  border-image-source: linear-gradient(116.97deg, rgba(255, 255, 255, 0) 16.71%, rgba(255, 255, 255, 0.4) 98.19%);
  background: #FFB804;
  border-radius: 12px;
  margin-bottom: 40px;
  margin-top: 24px;
  width: 465px;
  animation: pulse 2s infinite ease-in-out; 


  @media (max-width: 600px) {
    width: 235px;  
    font-size: 12px;
    font-weight: 1000;
    line-height: 15px;
    padding: 10px 0;
    margin-top: 16px;
    margin-bottom: 13px;

  }
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0px 0px 10px 0px #FFB804;
  }
  50% {
    box-shadow: 0px 0px 30px 10px #FFB804;
  }
}

.content_block_mobile {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 429px;
  gap: 16px;
  text-align: center;

  @media (max-width: 600px) {
    gap: 10px;
    width: 235px;
   }
}

.image_block_mobile {
  position: relative;
  height: 400px;

  @media (max-width: 600px) {
    height: 220px;
   }
}

.sweet_mobile {
  position: absolute;
  width: 608px;
  height: 300px;
  top: 20px;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 600px) {
    width: 313px;
    height: 169px;
    top: 10px;

   }
}

.sweet_bg_mobile {
  position: absolute;
  width: 670px;
  height: 365px;
  top: 30px;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 600px) {
    width: 346px;
    height: 186px;
    top: 30px;
   }
}

.fly_mobile {
  position: absolute;
  width: 389px;
  height: 389px;
  left: 50%;
  transform: translate(-50%);
  top: -20px;

  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
   }
}

.percent_mobile {
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 25px;

    
   }
}

.block_cash {
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    gap: 12px;
   }
}

.text_mobile  {
  font-size: 64px;
  font-weight: 900;
  line-height: 80px;
  white-space: nowrap;
  background: linear-gradient(90deg, #E0AA3E 0%, #F9F295 33.4%, #E0AA3E 67%, #F7EF8A 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
   }
}

.text_desc_mobile {
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  background: #0075FF;
  padding: 8px 16px;
  border-radius: 16px;
  width: max-content;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
    padding: 4px 8px;

   }
}

.footer_mobile {
  display: flex;
  flex-direction: column;
  bottom: 10px;

  max-width: 660px;
  width: 100%;
  gap: 8px;

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    white-space: wrap;

    @media (max-width: 680px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.block_mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bonus_block {
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 600px) {
    gap: 15px;
  }
}

.path_mobile {
  margin: 0px 0 50px 105px;
  width: 734px;
  height: 82px;

  @media (max-width: 600px) {
    width: 207px;
    height: 208px;
    margin: 0 0 30px;

  }
}